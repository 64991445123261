<template>
    <div class="pos-r"
         :class="{'text-center-res': lgAlignLeft,'stat-min-h hover-design': design ==='basic-b','text-left':textAlign === 'left','text-center':textAlign ==='center','card b-2 bs-3':border ===true,'py-3':size==='xs', 'py-4':size==='sm'}">
        <section v-if="design === 'basic-b'">
            <slot name="pre"></slot>
        </section>
        <h5 class="font-inter-medium mb-2"
            :class="{'text-lg':size==='lg','text-sm':size ==='sm', 'fs-xs': size === 'xs', 'text-gradient': design === 'basic-a', 'text-3': design === 'basic-c'}">
            {{ serial }}</h5>
        <div class="pos-r" :class="{'text-left':textAlign === 'left','text-center':textAlign ==='center'}">
            <span class="line-right"></span>
            <h3 class="font-inter-bold m-0"
                :class="{'fs-lg-5':size==='lg','fs-lg-1':size ==='sm','xs-val-heading':size ==='xs'}">{{ title }}</h3>
            <p :class="{'border-right':borderRight}" class="pr-3 fs--3 mb-2 font-inter-regular text-2">
                {{ content }}
            </p>
        </div>
        <section>
            <slot></slot>
        </section>
    </div>
</template>

<script>
export default {
    name  : 'CustomDashboardStat',
    props : {
        serial : {
            type    : String,
            default : ''
        },
        borderRight : {
            type    : Boolean,
            default : true
        },
        border : {
            type    : Boolean,
            default : false
        },
        lgAlignLeft : {
            type    : Boolean,
            default : false
        },
        title : {
            type    : String,
            default : ''
        },
        content : {
            type    : String,
            default : ''
        },
        textAlign : {
            type    : String,
            default : 'left'
        },
        size : {
            type    : String,
            default : 'sm'
        },
        design : {
            type    : String,
            default : 'basic-a'
        }
    }
};
</script>

<style scoped lang="scss">
.large-text {
    font-weight: 900;
}

.text-lg {
    font-size: 2.5rem;
    font-weight: bold;
    //color: var(--color-white);
    font-weight: 900;
    @media (min-width: 992px) {
        font-size: 3rem;
    }
}

.stat-min-h {
    min-height: 11rem;
}

.text-sm {
    font-size: 2rem;
    font-weight: 600;
}

.fs-xs {
    font-size: 1.5rem;
    font-weight: 600;
}

.xs-val-heading {
    font-size: 0.8rem;
}

//.border-right {
//    border-right: 2px solid var(--color-success-400);
//}

.line-right {
    position: absolute;
    right: 0;
    width: 1.1px;
    height: 50%;
    transform: translateY(50%);
    //background-color:var(--color-success-400);
    background-color: var(--bottom-tag-color);
}

.text-gradient {
    background: rgb(26, 118, 207);
    background: linear-gradient(to bottom right, rgba(26, 118, 207, 1) 0%, rgba(30, 45, 164, 1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 300%;
    background-position: -100%;

    animation: animatedText 2s infinite alternate-reverse;
}

.hover-design {
    transition: all 0.5s ease-in-out !important;
    &:hover {
        border: 2px solid var(--bg-8) !important;
    }
}
</style>
