<template>
    <div class="row">

        <div class="col-lg-4 col-12 col-md-6 mt-3" @click="$router.push('/auctions-list/Completed/')">
            <div class="">
                <custom-dashboard-stat size="sm" :border="true" text-align="center"
                                       :serial="auctionData.completed_auction"
                                       title="Completed Auctions" content="">
                    <template #default>
                        <img src="../../../assets/web/dashboard/stats/widget-1.png" class="icon-md" alt="">
                    </template>
                </custom-dashboard-stat>
            </div>
        </div>

        <div class="col-lg-4 col-12 col-md-6 mt-3" @click="$router.push('/auctions-list/Created/')">
            <custom-dashboard-stat :border="true" text-align="center"
                                   :serial="auctionData.active_auctions"
                                   title="Active Auctions" content="">
                <template #default>
                    <img src="../../../assets/web/dashboard/stats/widget-2.png" class="icon-md" alt="">
                </template>
            </custom-dashboard-stat>
        </div>

        <div class="col-lg-4 col-12 col-md-6 mt-3" @click="$router.push('/users-list/True')">
            <custom-dashboard-stat :border="true" text-align="center"
                                   :serial="auctionData.active_users"
                                   title="Active Users" content="">
                <template #default>
                    <img src="../../../assets/web/dashboard/stats/widget-3.png" class="icon-md" alt="">
                </template>
            </custom-dashboard-stat>
        </div>

        <div v-if="currentUser.is_bank_admin || currentUser.is_superuser
            || currentUser.is_ventura_admin || currentUser.permission.can_view_live_auction"
             class="col-lg-4 col-12 col-md-6 mt-3" @click="$router.push('/live-auction/')">
            <custom-dashboard-stat :border="true" text-align="center"
                                   :serial="auctionData.on_going_auctions"
                                   title="Live Auctions" content="">
                <template #default>
                    <img src="../../../assets/web/dashboard/stats/widget-4.png" class="icon-md" alt="">
                </template>
            </custom-dashboard-stat>
        </div>

        <div v-else class="col-lg-4 col-12 col-md-6 mt-3">
            <custom-dashboard-stat :border="true" text-align="center"
                                   :serial="auctionData.on_going_auctions"
                                   title="Live Auctions" content="">
                <template #default>
                    <img src="../../../assets/web/dashboard/stats/widget-4.png" class="icon-md" alt="">
                </template>
            </custom-dashboard-stat>
        </div>

        <div v-if="currentUser.is_bank_admin || currentUser.is_superuser
            || currentUser.is_ventura_admin || currentUser.permission.can_view_bidder"
             class="col-lg-4 col-12 col-md-6 mt-3"
             @click="$router.push('/bidders-list/')">
            <custom-dashboard-stat :border="true" text-align="center"
                                   :serial="auctionData.bidders"
                                   title="Bidders" content="">
                <template #default>
                    <img src="../../../assets/web/dashboard/stats/widget-5.png" class="icon-md" alt="">
                </template>
            </custom-dashboard-stat>
        </div>

        <div v-else class="col-lg-4 col-12 col-md-6 mt-3">
            <custom-dashboard-stat :border="true" text-align="center"
                                   :serial="auctionData.bidders"
                                   title="Bidders" content="">
                <template #default>
                    <img src="../../../assets/web/dashboard/stats/widget-5.png" class="icon-md" alt="">
                </template>
            </custom-dashboard-stat>
        </div>

        <div class="col-lg-4 col-12 col-md-6 mt-3" @click="$router.push('/drafted-auctions-list/')">
            <custom-dashboard-stat :border="true" text-align="center"
                                   :serial="auctionData.drafted_auctions"
                                   title="Drafted Auctions" content="">
                <template #default>
                    <img src="../../../assets/web/dashboard/stats/widget-6.png" class="icon-md" alt="">
                </template>
            </custom-dashboard-stat>
        </div>

    </div>
</template>

<script>
import CustomDashboardStat from '@components/dashboard/CustomDashboardStat';
import axios from 'secure-axios';
import urls from '@/data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'DashboardStatsSection',
    computed : {
        ...mapGetters(['currentUser'])
    },

    components : { CustomDashboardStat },

    data () {
        return {
            auctionData : []
        };
    },

    mounted () {
        this.loadDetails();
    },

    methods : {

        async loadDetails () {
            const response = await axios.get(urls.auth.bankAdminDashboard);

            if (response.data.success) {
                this.auctionData = response.data.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type : 'danger'
                });
            }
        }

    }
};
</script>

<style scoped>

</style>
