<template>
    <div class="home max-w-res of-h pt-4 pt-lg-5">
        <div class="">
            <div class="row">
                <div class="col-lg-9 order-2 order-lg-1">
                    <div class="row">
                        <div class="col-lg-12">
                            <header-component :fixed-top="false" :border-bottom="false"></header-component>
                        </div>
                        <div class="col-lg-12">
                            <all-auctions-card :is-super-admin="false"></all-auctions-card>
                        </div>

                        <div class="col-12">
                            <admin-dashboard-stats-section v-if="currentUser.is_superuser"></admin-dashboard-stats-section>

                            <dashboard-stats-section v-else></dashboard-stats-section>
                        </div>

                        <div class="col-lg-4 mt-3">
                        </div>

                    </div>
                </div>

                <div class="col-lg-3 order-1 order-lg-2 py-3 border-r-1 border-left m-0 p-0">
                    <avatar-and-notifications class=""></avatar-and-notifications>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-3">
                    <!--                    <btn text="Add Item" @click="$refs.addModal.show()"></btn>-->
                    <modal class="c-modal no-close" no-close-on-backdrop title="Add Folder" ref="addModal" width="30r"
                           header-color="">
                        <s-form>
                            <validated-input label="Add Item"></validated-input>
                            <div class="fl-x-bc mt-3">
                                <btn size="sm" text="Save" border-radius="0" :disabled="loading" :loading="loading"
                                     loading-text="Saving..."/>
                                <btn size="sm" class="ml-2 btn-basic-b" border-radius="0" :disabled="loading"
                                     @click="$refs.addModal.close()"
                                     text="Cancel" type="button"/>
                            </div>
                        </s-form>
                    </modal>
                </div>

                <div class="col-lg-3"></div>
                <div class="col-lg-3"></div>
                <div class="col-lg-3"></div>
            </div>
        </div>
    </div>

</template>

<script>
import AllAuctionsCard from '../components/dashboard/AllAuctionsCard';
import bankLogo from '../assets/web/dashboard/logos/yes-logo.png';
import DashboardStatsSection from '../components/dashboard/sections/DashboardStatsSection';
import AvatarAndNotifications from '../components/dashboard/sections/AvatarAndNotifications';
import AdminDashboardStatsSection from '../components/dashboard/sections/AdminDashboardStatsSection';
import { mapGetters } from 'vuex';
import HeaderComponent from '../components/HeaderComponent';
import axios from 'secure-axios';
import store from '@/store';
import urls from '../data/urls';

export default {
    name : 'Home',

    components : {
        HeaderComponent,
        AdminDashboardStatsSection,
        AvatarAndNotifications,
        DashboardStatsSection,
        AllAuctionsCard
    },
    computed : {
        ...mapGetters(['currentUser'])
    },

    data () {
        return {
            loading  : false,
            bankLogo : bankLogo,
            userType : 'normal'
        };
    },

    beforeMount () {
        this.refreshCurrentUser();
    },

    methods : {
        async refreshCurrentUser () {
            const response = await axios.get(urls.auth.status);
            if (response.data.loggedIn === true) {
                store.commit('setUser', response.data.user);
            }
        }
    }
};

</script>
<style scoped lang="scss">
.max-w-res {
    @media (min-width: 992px) {
        //max-width: 76vw;
        max-width: 80vw;
    }
    @media (min-width: 1400px) {
        //max-width: 75vw !important;
        max-width: 100vw !important;
    }
    @media (min-width: 1800px) {
        max-width: 90vw !important;
    }
}
</style>
