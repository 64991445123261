<template>
    <div class="b-2 bs-3 pos-r of-h bg-2"
         :class="{'card':!currentUser.is_superuser, 'border-r-3': currentUser.is_superuser}">
        <!--                <span class="bottom-right-tag bg-primary px-3"></span>-->
        <div class="arrow-right pos-a r--5p bt-0 rotate-45deg" v-if="!currentUser.is_superuser"></div>
        <div class="fl-te-c-res fl-j-sb mb--6p">
            <div class="fl-order-2 fl-order-lg-1 p-4 px-lg-5">
                <custom-dashboard-stat :lg-align-left="true" class="text-left " :design="cardTextDesign" size="lg"
                                       :serial="currentUser.auction_count" title="Upcoming Auctions."
                                       content=""></custom-dashboard-stat>
                <div class="text-center-res">
                    <btn
                        v-if="currentUser.is_superuser || currentUser.is_bank_admin || currentUser.permission.can_add_auction"
                        @click="$router.push('/add-auction/')" color="secondary" class="c-bg-7 px-3"
                        text="Add Auction"></btn>
                </div>
            </div>
            <div class="fl-order-1 fl-order-lg-2 order--2 mb-3 mb-lg-0 w-lg-50p">
                <div v-if="currentUser.is_superuser" class="of-h">
                    <img class="img-fluid of-h h-100" src="../../assets/web/dashboard/all-auction-img.jpg" alt="">
                </div>
                <div class="fl-x-cc h-100" v-else>
                    <img class="w-50" src="../../assets/web/dashboard/ban-img.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomDashboardStat from '../../components/dashboard/CustomDashboardStat';
import { mapGetters } from 'vuex';

export default {
    name : 'AllAuctionsCard',

    components : { CustomDashboardStat },

    computed : {
        ...mapGetters(['currentUser']),
        cardTextDesign () {
            if (this.currentUser.is_superuser) {
                return 'basic-c';
            }
            return 'basic-a';
        }
    },
    props : {
        isSuperAdmin : {
            type    : Boolean,
            default : false
        }
    }
};
</script>

<style scoped lang="scss">
.bottom-right-tag {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 4rem;
    height: 2rem;
    border-radius: 0 0 var(--card-radius) 0;
}

.arrow-right {
    width: 0;
    height: 0;
    overflow: hidden;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    //border-radius: 0 0 var(--card-radius) 0;
    border-left: 30px solid var(--color-primary);
    bottom: -19px;
    right: -4px;
}

.r--5p {
    right: -5px !important;
}

.mb--6p {
    margin-bottom: -6px !important;
}
</style>
