<template>
    <div class="m-0 px-3 pos-f-res top-0 w-res z-index-max d-sm-none-lg-block">

        <super-admin-avatar-card v-if="currentUser.is_superuser || currentUser.is_ventura_admin"
                                 :name="currentUser.name" designation=""
                                 :img="currentUser.bank.bank_logo || avatarPlaceholder"
                                 details-path="/">

            <template #details>
                <div class="text-center font-weight-medium">
                    <div class="mb-1 fs--1">
                        <span>Email:</span><span v-html="currentUser.email">josephth@gmail.com</span>
                    </div>
                    <div class="mb-1 fs--1">
                        <span>Phone:</span><span v-html="currentUser.mobile_number">+91 9376835131</span>

                    </div>
                </div>
            </template>

        </super-admin-avatar-card>

        <avatar-card class="" :name="currentUser.name" designation=""
                     :img="currentUser.bank.bank_logo || avatarPlaceholder"
                     details-path="/" v-else>

            <template #details>
                <div class="text-center font-inter-medium fs-lg--1">
                    <div class="mb-1 fs--2">
                        <span>Email: </span><span v-html="currentUser.email">minuthomas@gmail.com</span>
                    </div>
                    <div class="mb-1 fs--2">
                        <span>Phone: </span><span v-html="currentUser.mobile_number">+91 9376835131</span>
                    </div>
                    <div class="fs--2">
                        <span>Role: </span>
                        <span v-if="currentUser.is_superuser">Super User</span>
                        <span v-else-if="currentUser.is_bank_admin">Bank Admin</span>
                        <span v-else-if="currentUser.is_bank_user">Bank User</span>
                        <span v-else>{{ currentUser.employee_role }}</span>
                    </div>
                </div>
            </template>

        </avatar-card>

        <div class="p-2 pt-4">
            <h4 class="text-center font-inter-bold fs-lg-2">Notifications</h4>

            <div v-if="dataLoading" class="text-center pt-5">
                <loading-animation></loading-animation>
            </div>

            <div v-else>
                <div class="max-h-40 thin-scrollbar of-a of-x-h" v-if="data.length > 0">
                    <notification-card :line-bottom="true" class="" v-for="i in data"
                                       :key="i" :data="i"></notification-card>
                </div>

                <p v-if="data.length === 0"
                   class="text-center font-inter-regular mb-0 fs-lg--1 pt-5">No new notifications.</p>
            </div>
        </div>

    </div>
</template>

<script>
import AvatarCard from '@components/dashboard/AvatarCard';
import avatarPlaceholder from '../../../assets/web/homee/User-Admin.png';
import NotificationCard from '@components/dashboard/NotificationCard';
import SuperAdminAvatarCard from '@components/dashboard/SuperAdminAvatarCard';
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name : 'AvatarAndNotifications',

    components : { SuperAdminAvatarCard, NotificationCard, AvatarCard },

    data () {
        return {
            loading           : false,
            avatarPlaceholder : avatarPlaceholder,
            dataLoading       : false,
            data              : []
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadData();
    },

    methods : {
        async loadData () {
            this.dataLoading = true;
            const response = await axios.get(urls.auth.notifications + '?notification=Side');
            const json = response.data;
            this.data = [...json.data];
            this.dataLoading = false;
        }
    }

};
</script>

<style scoped lang="scss">
.max-h-40 {
    max-height: 40vh !important;
    overflow-y: auto;
    @media (min-width: 1400px) {
        //max-height: unset !important;
        max-height: 50vh !important;
    }
}

.w-res {
    @media (min-width: 1400px) {
        //width: 26rem !important;
        width: 21rem !important;
    }
    //@media (min-width: 1800px) {
    //    max-width: 38vw !important;
    //}
}
</style>
